// External
import type { MessageDescriptor } from 'react-intl';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import USEINTL_COMMON_TAGS from '~hooks/useIntlCommonTags';

// Types
interface FormattedMessageProps extends MessageDescriptor {
  id: string;
  values?: object;
}

// Wrapper component used to inject common HTML tags in translations
export const FormattedMessage = ({
  values,
  ...restProps
}: FormattedMessageProps) => (
  <IntlFormattedMessage
    {...restProps}
    values={{ ...USEINTL_COMMON_TAGS, ...values }}
  />
);

export default FormattedMessage;
