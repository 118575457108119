// Types
import type { Locale } from '~lang/localeDrivenConfig';

export const LOCALE_TO_TLD: { [key: Locale]: string } = {
  'ca-ES': 'es',
  'de-DE': 'de',
  'en-CA': 'ca',
  'en-GB': 'co.uk',
  'en-US': 'com',
  'es-ES': 'es',
  'fr-CA': 'ca',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'pt-PT': 'pt',
};

export const LOCALES = Object.keys(LOCALE_TO_TLD);

// Alternative (non-default) locales
export const ALT_LOCALES: Locale[] = ['ca-ES', 'fr-CA'];

// Set `pathname` locale prefix for non-default locales
export const getLocalePrefix = (locale: string) =>
  ALT_LOCALES.includes(locale) ? `/${locale}` : '';
